import axios from 'axios';

const getToken = () => {
    const token = localStorage.getItem("msal.idtoken");
    if(token) {
        return {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', 
              'Accept':'application/json',
              'Access-Control-Allow-Origin':'*'
        } 
    }  
}

export const ApiService = async ({path, method = 'get', payload = null, type = null, timeout = null, params}) => {
    const headers = getToken();
    return await axios({
        method: method, 
        url:`${path}`, 
        headers: headers, 
        data: payload, 
        responseType: type,
        timeout: timeout,
        params: params
    })
}

export const fetchGroupDetails = async () => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem('msal.idtoken');
  
      if (!token) {
        throw new Error('No token found in local storage');
      }
  
      const response = await fetch(
        'https://api.carrierappshub1.com/api/users/GetUserProfileAndPermissions',
        {
          headers: {
            'Accept': '*/*',
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.groups || []; // Return the groups
    } catch (error) {
      console.error('Error fetching group details:', error);
      throw error;
    }
  };
  