import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%", // Ensures the card takes full width
  },
  media: {
    paddingTop: "0%", // 16:9
  },
  avatar: {
    backgroundColor: "transparent",
    width: "100px",
  },
  top: {
    marginTop: "10px",
  },
  grow: {
    flexGrow: 1,
  },
  btn: {
    color: "black",
  },
  appbar: {
    background: "#0060b9",
  },
  title: {
    flexGrow: 1,
  },
  uploadBox: {
    float: "left",
    margin: "15px",
    width: "100%",
  },
  infobox: {
    float: "left",
    margin: "15px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#0060b9",
  },
  uploadpanel: {
    height: "250px",
  },
  downloadsPanel: {
    width: "40%",
  },
  rootgrid: {
    width: "90%",
    right: 0,
    left: 0,
    margin: "auto",
  },
  noPadding: {
    padding: "0 !important",
    borderRadius: "7px",
    border: "2px solid #a3c8edc9",
    marginRight: "50px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectformControl: {
    margin: theme.spacing(1),
    width: 280,
  },
  dropzonearea: {
    minHeight: "185px",
  },

  space: {
    width: "4px",
    height: "auto",
    display: "inline-block",
  },
}));

export default useStyles;
