// App.js
import React from "react";
import { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { Route, Routes } from "react-router-dom";
import AppHome from "./pages/AppHome";
import Dashboard from "./pages/Dashboard";
import FileDownloads from "./pages/Downloads";
import UploadFile from "./pages/FileUploads";
import NotFound from "./pages/NotFound";
import { fetchGroupDetails } from "./apiService";
// import { ReactAuthWrapper } from "@carrier/reactauthwrapper";
import { ReactAuthWrapper } from "@carrier-sce-sharedpackages/reactauthwrapper";
import appConfig from "./appConfig";
import AppHomeSales from "./pages/AppHomeSales";
import AppHomeAdmin from "./pages/AppHomeAdmin";
import PageNotfound from "./pages/PageNotfound";
import { BrowserRouter } from "react-router-dom";

const App = ({ username, classes, fetchAPI }) => {
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);
  const [isSalesGroup, setIsSalesGroup] = useState(false);
  const [appAccess, setAppAccess] = useState(false);
  const callback = (permissions) => {
    console.log("permissions->", permissions);
    if (permissions.appAccess) {
      console.log("permissions->", permissions.appAccess);
      setAppAccess(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const groupDetails = await fetchGroupDetails();
        setGroups(groupDetails);

        // Find the group related to 'Res Rating Tool'
        const salesGroup = groupDetails.some(group => group.groupName === 'Res Rating Tool' || group.groupName === 'MBD');
        // console.log("salesGroup",salesGroup)

        // Log and set the state based on the presence of the group
        if (salesGroup) {
          console.log("App flag", isSalesGroup);
        } else {
          setIsSalesGroup(true);
          console.log("Salesgroup flag", isSalesGroup);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);
  return (

      <ReactAuthWrapper appConfig={appConfig.api} getPermissions={callback}>
        {appAccess ? (
          <Container
            component="main"
            fixed
            maxWidth="lg"
            className={classes.media}
          >
            <div>

              <Routes>
                <Route
                  path="/"
                  element={isSalesGroup ?
                    <AppHomeSales
                      username={username}
                      classes={classes}
                      fetchAPI={fetchAPI}
                      flag={isSalesGroup}
                    /> : <AppHomeAdmin
                      username={username}
                      classes={classes}
                      fetchAPI={fetchAPI}
                      flag={isSalesGroup}
                    />
                  }
                />
                <Route
                  path="/Dashboard"
                  element={isSalesGroup ?
                    <PageNotfound /> :
                    <Dashboard
                      username={username}
                      classes={classes}
                      fetchAPI={fetchAPI}
                    />
                  }
                />
                <Route
                  path="/Downloads"
                  element={
                    <AppHomeSales
                    username={username}
                    classes={classes}
                    fetchAPI={fetchAPI}
                    flag={isSalesGroup}
                  />
                  }
                />
                <Route
                  path="/Uploads"
                  element={isSalesGroup ?
                    <PageNotfound /> : (
                      <div>
                        <div>
                          <AppHomeAdmin
                            username={username}
                            classes={classes}
                            fetchAPI={fetchAPI}
                            flag={isSalesGroup}
                          />
                        </div>
                      </div>)
                  }
                />
                <Route
                  path="*"
                  element={<NotFound username={username} classes={classes} />}
                />
              </Routes>
            </div>
          </Container>
        ) : (
          <Container>
            <div></div>
          </Container>
        )}
      </ReactAuthWrapper>
  );
};

export default App;
