import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { CssBaseline } from "@material-ui/core";
import * as serviceWorker from "./serviceWorker";
import useStyles from "./pages/Styles";
import axios from "axios";
import getUsernameFromToken from "./pages/User";

function Root() {
  const classes = useStyles(); // Apply styles
  //  const fetchAPI = axios.create({baseURL: "https://residentialratingtool.com/api" } );
  const fetchAPI = axios.create({
    // baseURL: "https://pt6om61t50.execute-api.us-east-1.amazonaws.com/dev/api",
    // baseURL: "https://d84k5xng08.execute-api.us-east-1.amazonaws.com/test/api",
    // baseURL:"http://localhost:8000/api"
    baseURL: "https://gjardsfgk1.execute-api.us-east-1.amazonaws.com/prod/api",
  });
  // const fetchAPI = axios.create({ baseURL: "http://localhost:8000/api" });

  const username = getUsernameFromToken();
  // console.log("Username:", username);
  return (

    <BrowserRouter>
      <div className={classes.root}>
      <CssBaseline />
        <App username={username} classes={classes} fetchAPI={fetchAPI} />
      </div>
      </BrowserRouter>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
