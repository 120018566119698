// NotFound.js
import React from "react";
import { Card, CardContent } from "@material-ui/core";
import Header from "./Header";
import CssBaseline from "@material-ui/core/CssBaseline";

const NotFound = ({ username, classes }) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Card className={classes.card}>
        <Header
          position="static"
          className={classes.appbar}
          username={username}
        ></Header>
        <CardContent>
          <div>
            <h1>404 Not Found</h1>
            <p>
              Sorry <b>{username},</b> the page you are looking for does not
              exist!
            </p>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default NotFound;
