import React, { useEffect, useState } from "react";
import "typeface-roboto";
import "../App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Chip from "@material-ui/core/Chip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  CircularProgress,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Provider } from "../store/Store";
import { GlobalStore } from "../store/Actions";
import Header from "../pages/Header";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
// import { DropzoneArea, DropzoneAreaBase } from "material-ui-dropzone";
import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import Drop from "../components/drop";
// import useStyles from "./Styles";

const options = ["Clean-Workspaces"];

const AppHomeAdmin = ({ username, classes, fetchAPI, flag }) => {
  const isSalesGroup = flag;
  const [open, setOpen] = useState(false);

  const [outdoorUnit, setoutdoorUnit] = useState([]);
  const [indoorUnit, setindoorUnit] = useState([]);
  const [furnace, setfurnace] = useState([]);
  const [fileObjects, setfileObjects] = useState([]);
  const [ahrifiles, setahrifiles] = useState([]);
  const [resetFiles, setresetFiles] = useState(true);
  const [uploadstatus, setuploadstatus] = useState("");
  const [timerID, settimerid] = useState("");
  const [openComment, setcommnet] = useState(false);
  const [userComment, setuserCommnet] = useState("");
  const [uploaddata, setuploaddata] = useState(new FormData());
  const [ahridata, setahridata] = useState(new FormData());
  const [ahriupload, setahriupload] = useState(false);

  var date = new Date();
  const [startDate, setstartDate] = useState(
    new Date(date.getFullYear() - 1, date.getMonth(), date.getDate())
  );
  const [endDate, setendDate] = useState(new Date());
  // let timerID = null;
  useEffect(() => {
    if (uploadstatus === "success") {
      setOpen(true);
      setResponse({
        Loading: false,
        Message: "Files uploaded Successfully !!!",
        isSuccess: true,
        isAlert: false,
      });
      setresetFiles(false);
      setfileObjects([]);
      setahrifiles([]);
      clearInterval(timerID);
      console.log("Files uploaded Successfully !!!");
    } else if (uploadstatus === "failed") {
      setOpen(true);
      setResponse({
        Loading: false,
        Message: "Failed to upload files. Please contact admin ..!!!",
        isSuccess: false,
        isAlert: true,
      });
      clearInterval(timerID);
    }
    setuploadstatus("");
    // console.log("we found success or failed..!!----", timerID)
  }, [uploadstatus]);

  const [selectedOutdoor, setselectedOutdoor] = useState([]);
  const [selectedIndoor, setselectedIndoor] = useState([]);
  const [selectedFurnace, setselectedFurnace] = useState([]);

  const [selectedCalTable, setselectedCalTable] = useState(false);
  const [selectedRunResultsTable, setselectedRunResultsTable] = useState(false);
  const [selectedRatingResultsTable, setselectedRatingResultsTable] =
    useState(false);
  const [selectedAHRIACResultsTable, setselectedAHRIACResultsTable] =
    useState(false);
  const [selectedAHRIHPResultsTable, setselectedAHRIHPResultsTable] =
    useState(false);
  const [selectedExpandedRatingsTable, setselectedExpandedRatingsTable] =
    useState(false);

  const [auditOutdoor, setauditOutdoor] = useState([]);
  const [NeepoutdoorModel, setNeepOutdoorModel] = useState([]);
  const [neepseloutdoorModel, setNeepSelOutdoorModel] = useState([]);
  const [opBaseModel, setOpBaseModel] = useState([]);
  const [opselBaseModel, setOpSelBaseModel] = useState([]);

  const [auditCalTable, setauditCalTable] = useState(false);
  const [auditExpandedRatings, setauditExpandedRatings] = useState(false);
  const [auditRatingResultsTable, setauditRatingResultsTable] = useState(false);
  const [auditAHRIACResultsTable, setauditAHRIACResultsTable] = useState(false);
  const [auditAHRIHPResultsTable, setauditAHRIHPResultsTable] = useState(false);
  const [auditTables, setauditTables] = useState([]);

  const [filterOutdoor, setFilterOutdoor] = useState([]);

  const [response, setResponse] = useState({
    isSuccess: false,
    Message: null,
    Note: null,
    Loading: true,
  });

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleChange = (newFiles) => {
  //   newFiles = newFiles.filter(
  //     (file) => !fileObjects.find((f) => f.data === file.data)
  //   );
  //   setfileObjects([...fileObjects, ...newFiles]);
  // };

  // const handleDelete = (deleted) => {
  //   setfileObjects(fileObjects.filter((f) => f !== deleted));
  // };

  const handleFilter = (event) => {
    postFilter({});
  };

  const handleAudit = (event) => {
    postAudit({});
  };

  const handleFilterRatingsResults = (event) => {
    postFilterRatingsResults({});
  };

  const handleFilter_to_download = (event) => {
    postFilter_download({});
  };

  const download_ahri_cond_char = (event) => {
    download_ahri_cond({});
  };
  const [oploading, setOpLoading] = useState(false);


  const [neeploading, setNeepLoading] = useState(false);

  const handleSubmit = (event) => {
    postFinish({ files: fileObjects });
  };

  const handleAhriSubmit = (event) => {
    // console.log(ahrifiles);
    setahriupload(true);
    if (ahrifiles.length > 0) {
      let accepted_files = ["AHRI_COND", "AHRI_REF"];
      let missing_files = [];

      const data = new FormData();
      for (var i = 0; i < ahrifiles.length; i++) {
        let file_name = ahrifiles[i].file.name;
        let ismatching = false;
        for (var j = 0; j < accepted_files.length; j++) {
          if (
            file_name.toLowerCase().indexOf(accepted_files[j].toLowerCase()) !==
            -1
          ) {
            // console.log(accepted_files[j])
            ismatching = true;
          }
        }
        if (!ismatching) {
          missing_files.push(ahrifiles[i].file.name);
        }
      }

      if (missing_files.length > 0) {
        setOpen(true);

        setResponse({
          Loading: false,
          Message: "Invalid filenames: " + missing_files.join(", ") + ".",
          Note:
            "Valid file names starts should with :" + accepted_files.join(", "),
          isSuccess: false,
          isAlert: true,
        });
        return "";
      }

      for (var i = 0; i < ahrifiles.length; i++) {
        data.append(ahrifiles[i].file.name, ahrifiles[i].file);
      }
      setahridata(data);
      setcommnet(true);
    } else {
      setResponse({
        Loading: false,
        Message: "Please select files to upload..",
        isSuccess: false,
        isAlert: true,
      });
    }
  };

  const exportAhriRef = async (event) => {
    let data = {};
    setOpen(true);

    data["Indoor"] = [];
    data["Outdoor"] = [];
    data["Furnace"] = [];
    data["AHRI_REF"] = true;

    data["Cal_Table"] = false;
    data["Run_Results"] = false;
    data["Rating_Results"] = false;
    data["AHRI_Results_AC"] = false;
    data["AHRI_Results_HP"] = false;
    data["Expanded_OD"] = false;

    setResponse({
      Loading: true,
      Message: "Please wait, we are processing !!!",
      isSuccess: false,
      isAlert: false,
    });
    //console.log("only RUN RESULTS is selected..!!")
    await fetchAPI
      .post("/download_ahri_ref", data, {
        timeout: 1000 * 32,
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
        },
      })
      .then((res) => {
        setResponse({
          Loading: false,
          Message: "AHRI_REF will be sent via Email!!!",
          isSuccess: true,
          isAlert: false,
        });
      });
  };

  const handleCommnetOK = async () => {
    let uplaodpayload;
    setcommnet(false);
    if (ahriupload) {
      uplaodpayload = ahridata;
    } else {
      uplaodpayload = uploaddata;
    }
    const blob = new Blob([userComment], { type: "text/plain" });
    uplaodpayload.append("comment.txt", blob, "comment.txt");

    setOpen(true);
    setResponse({
      Loading: true,
      Message: "Please wait, Files are Uploading !!!",
      isSuccess: false,
      isAlert: false,
    });

    let prefix = { prefix: "uploaded_files" };
    const s3_url = await fetchAPI.post("/geturl", prefix, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
      },
    });
    console.log(s3_url);

    const zip = new JSZip();
    for (var value of uplaodpayload.values()) {
      console.log(value.name, value);
      zip.file(value.name, value);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      let new_data = new FormData();
      Object.keys(s3_url.data.fields).forEach((key) => {
        new_data.append(key, s3_url.data.fields[key]);
      });
      new_data.append("file", content);
      axios
        .post(s3_url.data.url, new_data, {
          headers: {
            // "Content-Type": "application/json;charset=UTF-8",
            "Content-type": "multipart/form-data",
            // "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
          },
        })
        .then((res) => {
          console.log(
            "files uploaded successfully...................................."
          );
          // console.log(res.data.file_name);
          setresetFiles(false);
          setfileObjects([]);
          setahrifiles([]);
          // let tempID = setInterval(
          //   getuploadstatus,
          //   10000,
          //   s3_url.data.fields.key
          // );
          // settimerid(tempID);
          console.log("stauts from main-----");
          setOpen(false);
        });
      // saveAs(content, s3_url.data.fields.key)
    });

    // fetchAPI.post("/upload_files", uplaodpayload, {
    //   headers: {
    //     "Content-type": "multipart/form-data",
    //     "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
    //   }
    // })
    //   .then(res => {
    //     console.log("files uploaded successfully....................................")
    //     console.log(res.data.file_name);
    //     setresetFiles(false);
    //     setfileObjects([]);
    //     let tempID = setInterval(getuploadstatus, 10000, res.data.file_name);
    //     settimerid(tempID)
    //     console.log("stauts from main-----");
    //     setOpen(false)
    //   });
    setuserCommnet("");
  };

  const handleCommnetCancel = () => {
    setcommnet(false);
    setOpen(false);
    setuserCommnet("");
  };

  let accepted_files = [
    "AHRI Results_AC",
    "AHRI Results_HP",
    "NEEP_DATA",
    "OPCOST",
    "Calc_Table",
    "Ratings_Results",
    "Run_Results",
    ".zip",
  ];

  const postFinish = () => {
    if (fileObjects.length > 0) {
      // let accepted_files = ['AHRI Results_AC.csv', 'AHRI Results_HP.csv', 'Calc_Table.csv', 'Ratings_Results.csv', 'Run_Results.csv', 'upload_files.zip']
      setahriupload(false);
      let accepted_files = [
        "AHRI Results_AC",
        "AHRI Results_HP",
        "Calc_Table",
        "Ratings_Results",
        "Run_Results",
        "OPCOST",
        "NEEP_DATA",
        ".zip",
        "_Carrier.csv",
        "_ICP.csv",
      ];
      let missing_files = [];

      const data = new FormData();
      for (var i = 0; i < fileObjects.length; i++) {
        let file_name = fileObjects[i].file.name;
        let ismatching = false;
        for (var j = 0; j < accepted_files.length; j++) {
          if (
            file_name.toLowerCase().indexOf(accepted_files[j].toLowerCase()) !=
            -1
          ) {
            // console.log(accepted_files[j])
            ismatching = true;
          }
        }
        if (!ismatching) {
          missing_files.push(fileObjects[i].file.name);
        }
      }

      if (missing_files.length > 0) {
        setOpen(true);

        setResponse({
          Loading: false,
          Message: "Invalid filenames: " + missing_files.join(", ") + ".",
          Note:
            "Valid file names starts should with :" + accepted_files.join(", "),
          isSuccess: false,
          isAlert: true,
        });
        return "";
      }

      for (var i = 0; i < fileObjects.length; i++) {
        data.append(fileObjects[i].file.name, fileObjects[i].file);
      }
      setuploaddata(data);
      setcommnet(true);
    } else {
      setResponse({
        Loading: false,
        Message: "Please uploade a file(s) !!!",
        isSuccess: false,
        isAlert: true,
      });
    }
  };

  const getuploadstatus = (event) => {
    // let status = "";
    let filename = {};
    console.log("event", event);
    filename["filename"] = event;
    fetchAPI
      .post("/upload_status", filename, {
        headers: {
          "Content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
        },
      })
      .then((res) => {
        setuploadstatus(res.data.status);
      });
  };

  const updatedefaultvalues = (event) => {
    setOpen(false);
    setresetFiles(false);
    setfileObjects([]);
    setahrifiles([]);
    setselectedOutdoor([]);
    setselectedIndoor([]);
    setselectedFurnace([]);
    setselectedCalTable(false);
    setselectedRunResultsTable(false);
    setselectedRatingResultsTable(false);
    setselectedAHRIACResultsTable(false);
    setselectedAHRIHPResultsTable(false);
    setselectedExpandedRatingsTable(false);
    setauditAHRIACResultsTable(false);
    setauditAHRIHPResultsTable(false);
    setauditCalTable(false);
    setauditExpandedRatings(false);
    setauditOutdoor([]);
    setFilterOutdoor([]);
    setauditRatingResultsTable(false);
    setauditTables([]);
  };
  const handledefaultvalues = (event) => {
    setOpen(false);
  };

  const postAudit = async () => {
    // debugger;
    let data = {};

    data["Outdoor"] =
      auditOutdoor.length > 0 ? auditOutdoor.map((v) => v.title) : "";
    data["coll_names"] = auditTables;
    data["from_date"] = startDate;
    data["to_date"] = endDate;

    setOpen(true);

    setResponse({
      Loading: true,
      Message: "Please wait, we are processing !!!",
      isSuccess: false,
      isAlert: false,
    });

    if (auditTables.length > 0) {
      try {
        //console.log("only RUN RESULTS is selected..!!")
        await fetchAPI
          .post("/audit", data, {
            timeout: 1000 * 32,
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
            },
          })
          .then((res) => {
            console.log(res, res.export_file_error, res.data);
            try {
              var decodedString = String.fromCharCode.apply(
                null,
                new Uint8Array(res.data)
              );
              var obj = JSON.parse(decodedString);
            } catch (err) {
              var obj = {};
            }
            console.log(obj);
            if (obj.export_file_error) {
              setResponse({
                Loading: false,
                Message: "Error while downloading Audit data !!!",
                isSuccess: true,
                isAlert: false,
              });
            } else if (obj.no_data_error) {
              setResponse({
                Loading: false,
                Message: "Audit data is not available in database !!!",
                isSuccess: false,
                isAlert: true,
              });
            } else {
              // const blob = new Blob([res.data], {
              //   type: "application/octet-stream",
              // });
              // const filename = "Audit.zip";
              // saveAs(blob, filename);

              setResponse({
                Loading: false,
                Message: "Audit Files will be sent on Email.!!",
                isSuccess: true,
                isAlert: false,
              });
            }
          })
          .catch((error) => {
            let message = "Error";
            let message_body = JSON.parse(
              String.fromCharCode.apply(
                null,
                new Uint8Array(error.response.data)
              )
            )?.message;
            if (message_body) {
              message = message_body;
            }
            setResponse({
              Loading: false,
              Message: message,
              isSuccess: false,
              isAlert: true,
            });
          });
      } catch (err) {
        console.log(
          "err?.response?.data?.message",
          err?.response?.data?.message
        );
        setResponse({
          Loading: false,
          Message: "Error",
          isSuccess: false,
          isAlert: true,
        });
      }
    } else {
      setResponse({
        Loading: false,
        Message: "Please select any of the table",
        isSuccess: false,
        isAlert: true,
      });
    }
  };

  const postFilterRatingsResults = async () => {
    setOpen(true);

    let data = {};
    data["Outdoors"] =
      filterOutdoor.length > 0 ? filterOutdoor.map((v) => v.title) : "";

    setResponse({
      Loading: true,
      Message: "Please wait, we are processing !!!",
      isSuccess: false,
      isAlert: false,
    });

    try {
      //console.log("only RUN RESULTS is selected..!!")
      await fetchAPI
        .post("/download_risk_report", data, {
          timeout: 1000 * 32,
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
          },
        })
        .then((res) => {
          console.log(res, res.export_file_error, res.data);
          try {
            var decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(res.data)
            );
            var obj = JSON.parse(decodedString);
          } catch (err) {
            var obj = {};
          }
          console.log(obj);
          if (obj.export_file_error) {
            setResponse({
              Loading: false,
              Message: "Error while downloading data !!!",
              isSuccess: true,
              isAlert: false,
            });
          } else {
            const blob = new Blob([res.data], {
              type: "application/octet-stream",
            });
            const filename = "Risk_Report.zip";
            saveAs(blob, filename);

            setResponse({
              Loading: false,
              Message: "Files Downloaded Successfully !!!",
              isSuccess: true,
              isAlert: false,
            });
          }
        })
        .catch((error) => {
          let message = "Error";
          let message_body = JSON.parse(
            String.fromCharCode.apply(null, new Uint8Array(error.response.data))
          )?.message;
          if (message_body) {
            message = message_body;
          }
          setResponse({
            Loading: false,
            Message: message,
            isSuccess: false,
            isAlert: true,
          });
        });
    } catch (err) {
      console.log("err?.response?.data?.message", err?.response?.data?.message);
      setResponse({
        Loading: false,
        Message: "Error",
        isSuccess: false,
        isAlert: true,
      });
    }
  };

  const postFilter = async (data1) => {
    let data = {};
    setOpen(true);

    data["Indoor"] =
      selectedIndoor.length > 0 ? selectedIndoor.map((v) => v.title) : [];
    data["Outdoor"] =
      selectedOutdoor.length > 0 ? selectedOutdoor.map((v) => v.title) : [];
    data["Furnace"] =
      selectedFurnace.length > 0 ? selectedFurnace.map((v) => v.title) : [];
    data["Cal_Table"] = selectedCalTable ? true : false;
    data["Run_Results"] = selectedRunResultsTable ? true : false;
    data["Rating_Results"] = selectedRatingResultsTable ? true : false;
    data["AHRI_Results_AC"] = selectedAHRIACResultsTable ? true : false;
    data["AHRI_Results_HP"] = selectedAHRIHPResultsTable ? true : false;
    data["Expanded_OD"] = selectedExpandedRatingsTable ? true : false;

    const axiosOptions = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
    };
    setResponse({
      Loading: true,
      Message: "Please wait, we are processing !!!",
      isSuccess: false,
      isAlert: false,
    });

    if (
      selectedRunResultsTable ||
      selectedAHRIACResultsTable ||
      selectedAHRIHPResultsTable ||
      selectedCalTable ||
      selectedRatingResultsTable ||
      selectedExpandedRatingsTable
    ) {
      try {
        //console.log("only RUN RESULTS is selected..!!")
        await fetchAPI
          .post("/export", data, {
            timeout: 1000 * 32,
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
            },
          })
          .then((res) => {
            setResponse({
              Loading: false,
              Message: "Files will be sent via Email!!!",
              isSuccess: true,
              isAlert: false,
            });
          })
          .catch((error) => {
            let message = "Error";
            let message_body = JSON.parse(
              String.fromCharCode.apply(
                null,
                new Uint8Array(error.response.data)
              )
            )?.message;
            if (message_body) {
              message = message_body;
            }
            setResponse({
              Loading: false,
              Message: message,
              isSuccess: false,
              isAlert: true,
            });
          });
      } catch (err) {
        console.log(
          "err?.response?.data?.message",
          err?.response?.data?.message
        );
        setResponse({
          Loading: false,
          Message: "Error",
          isSuccess: false,
          isAlert: true,
        });
      }
    } else {
      setResponse({
        Loading: false,
        Message: "Please select any of the table",
        isSuccess: false,
        isAlert: true,
      });
    }
  };

  const download_ahri_cond = async (data1) => {
    let data = {};
    setOpen(true);

    data["AHRI_COND_CHAR"] = true;

    const axiosOptions = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
    };
    setResponse({
      Loading: true,
      Message: "Please wait, Files are Downloading !!!",
      isSuccess: false,
      isAlert: false,
    });

    try {
      fetchAPI
        .post("/download_cond_char", data, {
          timeout: 1000 * 32,
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
          },
        })
        .then((res) => {
          console.log(res, res.export_file_error, res.data);
          try {
            var decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(res.data)
            );
            var obj = JSON.parse(decodedString);
          } catch (err) {
            var obj = {};
          }
          console.log(obj);
          if (obj.export_file_error) {
            setResponse({
              Loading: false,
              Message: "Data is not available in database !!!",
              isSuccess: false,
              isAlert: true,
            });
          } else {
            const blob = new Blob([res.data], {
              type: "application/octet-stream",
            });
            const filename = "AHRI_COND_CHAR.zip";
            saveAs(blob, filename);

            setResponse({
              Loading: false,
              Message: "Files Downloaded Successfully !!!",
              isSuccess: true,
              isAlert: false,
            });
          }
        });
    } catch (err) {
      console.log(err);
      setResponse({
        Loading: false,
        Message: "Error while exporting AHRI_COND_CHAR..!",
        isSuccess: false,
        isAlert: true,
      });
    }
  };

  const postFilter_download = async (data1) => {
    let data = {};
    setOpen(true);

    data["Indoor"] =
      selectedIndoor.length > 0 ? selectedIndoor.map((v) => v.title) : [];
    data["Outdoor"] =
      selectedOutdoor.length > 0 ? selectedOutdoor.map((v) => v.title) : [];
    data["Furnace"] =
      selectedFurnace.length > 0 ? selectedFurnace.map((v) => v.title) : [];
    data["Cal_Table"] = selectedCalTable ? true : false;
    data["Run_Results"] = selectedRunResultsTable ? true : false;
    data["Rating_Results"] = selectedRatingResultsTable ? true : false;
    data["AHRI_Results_AC"] = selectedAHRIACResultsTable ? true : false;
    data["AHRI_Results_HP"] = selectedAHRIHPResultsTable ? true : false;

    const axiosOptions = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
    };
    setResponse({
      Loading: true,
      Message: "Please wait, Files are Downloading !!!",
      isSuccess: false,
      isAlert: false,
    });

    if (selectedRunResultsTable || selectedExpandedRatingsTable) {
      setResponse({
        Loading: false,
        Message: "Use Export option to get data !!!",
        isSuccess: false,
        isAlert: true,
      });
    } else if (
      selectedAHRIACResultsTable ||
      selectedAHRIHPResultsTable ||
      selectedCalTable ||
      selectedRatingResultsTable
    ) {
      try {
        fetchAPI
          .post("/download", data, {
            timeout: 1000 * 32,
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
            },
          })
          .then((res) => {
            console.log(res, res.export_file_error, res.data);
            try {
              var decodedString = String.fromCharCode.apply(
                null,
                new Uint8Array(res.data)
              );
              var obj = JSON.parse(decodedString);
            } catch (err) {
              var obj = {};
            }
            console.log(obj);
            if (obj.export_file_error) {
              setResponse({
                Loading: false,
                Message: "Data is not available in database !!!",
                isSuccess: false,
                isAlert: true,
              });
            } else {
              const blob = new Blob([res.data], {
                type: "application/octet-stream",
              });
              const filename = "Export.zip";
              saveAs(blob, filename);

              setResponse({
                Loading: false,
                Message: "Files Downloaded Successfully !!!",
                isSuccess: true,
                isAlert: false,
              });
            }
          });
        //          .catch(error => {
        //            console.log(error)
        //            setResponse({
        //              Loading: false,
        //              Message: "Unable to download large data..!! Please contact admin for manual download..!!",
        //              isSuccess: false,
        //              isAlert: true,
        //            });
        //          });
      } catch (err) {
        console.log(err);
        setResponse({
          Loading: false,
          Message: "Error",
          isSuccess: false,
          isAlert: true,
        });
      }
    } else {
      setResponse({
        Loading: false,
        Message: "Please select any of the table",
        isSuccess: false,
        isAlert: true,
      });
    }
  };

  const fetchData = async () => {
    setOpen(true);
    setResponse({
      Loading: true,
      Message: "",
      isSuccess: false,
      isAlert: false,
    });

    // const filter_input_result = await fetchAPI.get("/getinfo", {
    //   headers: {
    //     "Content-Type": "application/json;charset=UTF-8",
    //     Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
    //   },
    // });

    const od_list = await fetchAPI.get("/getinfo", {
      params: { para: "Outdoor" },
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
      },
    });

    const id_list = await fetchAPI.get("/getinfo", {
      params: { para: "Indoor" },
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
      },
    });

    const fur_list = await fetchAPI.get("/getinfo", {
      params: { para: "Furnace" },
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
      },
    });

    const { data: op_cost } = await fetchAPI.get("/load_metadata", {
      params: { collection: "OP_COST_LOG" },
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
      },
    });

    const { data: neep_data } = await fetchAPI.get("/load_metadata", {
      params: { collection: "NEEP_DATA" },
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
      },
    });

    // console.log("neee", neep_data.data);

    if (op_cost.data["Base Model"].length) {
      setOpBaseModel(op_cost.data["Base Model"]);
    }

    if (neep_data.data["Base Model"].length) {
      // console.log(neep_data.data["Furnace Model Number (if applicable)"]);
      setNeepOutdoorModel(neep_data.data["Base Model"]);
    }
    // const filter_input_result = await axios("https://residentialratingtool.com/api/getinfo");
    // filter_input_result = JSON.parse(filter_input_result.data)
    if (id_list.data || od_list.data || fur_list.data) {
      const indoorOptions = [];
      for (var key in id_list.data.Indoor.sort()) {
        indoorOptions.push({ title: id_list.data.Indoor[key] });
      }

      const outdoorOptions = [];
      for (var key in od_list.data.Outdoor.sort()) {
        outdoorOptions.push({ title: od_list.data.Outdoor[key] });
      }

      const furnaceOptions = [];
      for (var key in fur_list.data.Furnace.sort()) {
        furnaceOptions.push({ title: fur_list.data.Furnace[key] });
      }
      setindoorUnit(indoorOptions);
      setoutdoorUnit(outdoorOptions);
      setfurnace(furnaceOptions);
      setfileObjects([]);
      setahrifiles([]);
      setOpen(false);
    } else {
      console.log("An Error Occurred in api !!!");
      setResponse({
        Loading: false,
        Message: "",
        isSuccess: false,
        isAlert: false,
      });
    }
  };

  useEffect(() => {
    // fetchData();
    setfileObjects([]);
    setahrifiles([]);
  }, [resetFiles]);
  return (
    <Provider stores={[GlobalStore]}>
      <React.Fragment>
        <CssBaseline />
        <Card className={classes.card}>
          <Header
            position="static"
            className={classes.appbar}
            username={username}
            flag={isSalesGroup}
          ></Header>
          <Box
            sx={{
              display: 'flex',  // Use flexbox layout
              flexDirection: 'column',  // Stack elements vertically
              width: '100%',  // Full width of the parent container
              height: '100vh',  // Ensure it takes up the full viewport height
              margin: 0,
              padding: 0,
              overflowX: 'hidden',  // Prevent horizontal scroll
            }}
          >
            <CardContent>
              {!isSalesGroup && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <label id={"lbl"} htmlFor={"rdo"}>
                      <Typography variant="h6" className={classes.btn}>
                        Upload CSV File(s)
                      </Typography>
                    </label>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container className={classes.rootgrid}>
                      <Grid item xs={12}>
                        <div className={"panel " + classes.uploadpanel}>
                          <div className={"information-box " + classes.uploadBox}>
                            <label id={"lbl"} htmlFor={"rdo"}>
                              <Typography variant="h6" className={classes.btn}>
                                Upload CSV File(s)
                              </Typography>
                            </label>
                            <Drop
                              css={classes.dropzonearea}
                              files={fileObjects}
                              setfiles={setfileObjects}
                              setfilelimit={10}
                            />
                            <p style={{ color: "red", font: "small-caption" }}>
                              * File names should start with:{" "}
                              {accepted_files.join(", ")}
                            </p>
                            <p style={{ color: "red", font: "small-caption" }}>
                              * Expanded Ratings files are allowd which ends with
                              '_Carrier.csv' and '_ICP.csv'
                            </p>
                            <Button
                              type="button"
                              style={{ top: "50%", left: "45%" }}
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              onClick={handleSubmit}
                            >
                              Upload
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}

              <div style={{ marginTop: "10px" }}></div>
              {isSalesGroup ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <label id={"lbl"} htmlFor={"rdo"}>
                      <Typography variant="h6" className={classes.btn}>
                        AHRI Operations
                      </Typography>
                    </label>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container className={classes.rootgrid}>
                      <Grid item xs={12}>
                        <div className={"panel " + classes.exportpanel}>
                          <div className={"information-box " + classes.exportBox}>
                            <div align="center">
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={download_ahri_cond_char}
                              >
                                DOWNLOAD AHRI COND
                              </Button>
                              <div
                                className={classes.space}
                                style={{ marginRight: "12px" }}
                              ></div>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={exportAhriRef}
                              >
                                DOWNLOAD AHRI REF
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <label id={"lbl"} htmlFor={"rdo"}>
                      <Typography variant="h6" className={classes.btn}>
                        AHRI Operations
                      </Typography>
                    </label>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container className={classes.rootgrid}>
                      <Grid item xs={12}>
                        <div className={"panel " + classes.uploadpanel}>
                          <div className={"information-box " + classes.uploadBox}>
                            <label id={"lbl"} htmlFor={"rdo"}>
                              <Typography variant="h6" className={classes.btn}>
                                Upload File(s)
                              </Typography>
                            </label>
                            <Drop
                              css={classes.dropzonearea}
                              files={ahrifiles}
                              setfiles={setahrifiles}
                              setfilelimit={2}
                            />
                            <div className={classes.space}></div>
                            <Grid container spacing={2} justify="space-between">
                              <Grid item>
                                <p
                                  style={{ color: "red", font: "small-caption" }}
                                >
                                  * File names should start with AHRI_COND and
                                  AHRI_REF
                                </p>
                              </Grid>
                              <Grid item>
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  // className={classes.submit}
                                  onClick={handleAhriSubmit}
                                >
                                  Upload
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}

              <div style={{ marginTop: "10px" }}></div>
              <div>
                <Grid>
                  <React.Fragment>
                    <Dialog disableEscapeKeyDown={false} open={open}>
                      <DialogContent>
                        {response.Loading && (
                          <CircularProgress></CircularProgress>
                        )}
                        <Typography
                          variant="h6"
                          color="primary"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {response.Message}
                        </Typography>
                        <Typography
                          style={{ color: "red", font: "small-caption" }}
                        >
                          {response.Note}
                        </Typography>
                      </DialogContent>
                      {response.isSuccess && !response.isAlert && (
                        <DialogActions>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={updatedefaultvalues}
                          >
                            Ok
                          </Button>
                        </DialogActions>
                      )}
                      {response.isAlert && (
                        <DialogActions>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handledefaultvalues}
                          >
                            Ok
                          </Button>
                        </DialogActions>
                      )}
                    </Dialog>
                    <Dialog disableEscapeKeyDown={false} open={openComment}>
                      <DialogTitle>
                        <Typography
                          component="span"
                          variant="h6"
                          color="primary"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          Comments
                        </Typography>
                      </DialogTitle>
                      <DialogContent>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          <TextField
                            onChange={(e) => setuserCommnet(e.target.value)}
                            autoFocus
                            fullWidth
                            placeholder="Your comments"
                          />
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={userComment.length < 1}
                          onClick={handleCommnetOK}
                        >
                          OK
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleCommnetCancel}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </Grid>
              </div>
            </CardContent>
          </Box>
        </Card>
      </React.Fragment>
    </Provider>
  );
};

export default AppHomeAdmin;
