import React, { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { CssBaseline } from "@material-ui/core";
import "./Dashboard.css";
import Header from "./Header";
import {
  Card,
  CardContent,
  IconButton,
  CircularProgress,
  Typography,
  Button
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const ExpandableCell = ({ files }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        variant="outlined"
        size="small"
      >
        {isExpanded ? "Hide" : `Show (${files.length})`}
      </Button>
      {isExpanded && (
        <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
          {files.map((file, index) => (
            <li key={index}>{file}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

const Dashboard = ({ classes, username, fetchAPI }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchAPI.get("/uploads", {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
        },
      });
      if (Array.isArray(response.data)) {
        setData(response.data);
      } else {
        console.error("Data returned is not an array:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Failed Files",
        accessor: "failed_files",
        Cell: ({ cell: { value } }) => <ExpandableCell files={value || []} />,
      },
      {
        Header: "Successful Files",
        accessor: "successful_files",
        Cell: ({ cell: { value } }) => <ExpandableCell files={value || []} />,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color:
                value === "SUCCESS"
                  ? "green"
                  : value === "FAILED"
                  ? "red"
                  : "orange",
              fontWeight: "bold",
            }}
          >
            {value || "N/A"}
          </span>
        ),
      },
      {
        Header: "Uploaded By",
        accessor: "uploaded_by",
        Cell: ({ cell: { value } }) => value || "Unknown",
      },
      {
        Header: "Uploaded On",
        accessor: "uploaded_on",
        Cell: ({ cell: { value } }) => value || "Unknown",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    usePagination
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <Card className={classes.card}>
        <Header
          position="static"
          className={classes.appbar}
          username={username}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <Typography variant="h6" className={classes.btn}>
            Upload History
          </Typography>
          <IconButton
            onClick={fetchData}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <RefreshIcon />
            )}
          </IconButton>
        </div>
        <CardContent>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{ backgroundColor: "navy", color: "white" }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </button>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
          </div>
          <p style={{ color: "red", font: "small-caption" }}>
            * User can see upload history of last 14 days
          </p>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default Dashboard;
