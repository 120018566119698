import React, { useState, useEffect } from "react";
import { DropzoneAreaBase } from "material-ui-dropzone";

const Drop = ({ css, files, setfiles, setfilelimit }) => {
    // const Drop = (props) => {

    const handleChange = (newFiles) => {
        newFiles = newFiles.filter(
            (file) => !files.find((f) => f.data === file.data)
        );
        setfiles([...files, ...newFiles]);
    };

    const handleDelete = (deleted) => {
        setfiles(files.filter((f) => f !== deleted));
    };

    return (
        <DropzoneAreaBase
            acceptedFiles={[
                ".csv",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "zip",
                "application/zip",
                "application/x-zip",
                "application/x-zip-compressed",
            ]}
            showFileNames={true}
            showPreviewsInDropzone={true}
            fileObjects={files}
            dropzoneClass={css}
            useChipsForPreview={true}
            maxFileSize={524288000}
            filesLimit={setfilelimit}
            clearOnUnmount={true}
            onAdd={handleChange}
            onDelete={handleDelete}
        />
    );
};

export default Drop;
