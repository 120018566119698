import React from "react";
import { Button, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PageNotfound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Adjust the route to your homepage
  };

  return (
    <Container
      style={{
        textAlign: "center",
        marginTop: "10%",
        padding: "20px",
      }}
    >
      <Typography variant="h4" color="error" gutterBottom>
        You are not authorized to access this page.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please connect with the Admin team for assistance.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        style={{ marginTop: "20px" }}
      >
        Go to Homepage
      </Button>
    </Container>
  );
};

export default PageNotfound;

