import React from "react";
import "typeface-roboto";
import "../App.css";
import { Link } from "react-router-dom";
import {
  Container,
  IconButton,
  Toolbar,
  Box,
  Tooltip,
  Avatar,
  AppBar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/logo.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import { logout } from "@carrier/reactauthwrapper";
import {logout} from '@carrier-sce-sharedpackages/auth-module';
import appConfig from "../appConfig";

const settings = ["Uploads","Dashboard", "Downloads", ];
const icons = [<UploadFileIcon />,<DashboardIcon />, <DownloadIcon />, ];
const settings_sales = ["Downloads"]; // "Uploads"];
const icons_sales = [<DownloadIcon />]; // <UploadFileIcon />];

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
  },
  media: {
    paddingTop: "0%", // 16:9
  },
  avatar: {
    backgroundColor: "transparent",
    width: "100px",
  },
  toolbar: {
    minHeight: 90,
  },
  top: {
    marginTop: "50px",
  },
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: "#0060b9",
  },
  title: {
    flexGrow: 1,
  },
}));

function Header(props) {
  const classes = useStyles();
  const { username, flag } = props;

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    logout(appConfig.api);
  };

  return (
    <AppBar position="static" className={classes.appbar}>
      <Container maxWidth="xl">
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
          >
            <Link to="/">
              <Avatar
                aria-label="Logo"
                src={logo}
                className={classes.avatar}
              ></Avatar>
            </Link>
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
              Ratings Agency Database
            </Link>
            <Typography className={classes.title} variant="body2" noWrap>
              One Stop For Data
            </Typography>
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            {(flag ? settings_sales : settings).map((setting, index) => (
              <Tooltip key={setting} title={setting}>
                <IconButton
                  component={Link}
                  to={`/${setting}`}
                  sx={{ p: 0 }}
                  style={{ color: "black" }}
                >
                  {(flag ? icons_sales : icons)[index]}
                </IconButton>
              </Tooltip>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={`Not ${username}? Logout`}>
              <IconButton
                onClick={handleLogout}
                sx={{ p: 0 }}
                style={{ color: "white" }}
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
